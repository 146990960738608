import React from "react";
import Select from "react-select";

const CustomDropdown = ({ options = [], selectedOption, onChange, placeholder }) => {
  const formattedOptions = options.map(option => ({ value: option.id, label: option.name }));

  const handleOptionSelect = (selectedOption) => {
    onChange({ id: selectedOption.value, name: selectedOption.label });
  };

  return (
    <Select
      value={selectedOption ? { value: selectedOption.id, label: selectedOption.name } : null}
      onChange={handleOptionSelect}
      options={formattedOptions}
      placeholder={placeholder}
      className="w-full"
    />
  );
};

export default CustomDropdown;
