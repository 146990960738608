import axios from "axios";
import { LocalMSTokens } from "./localstorage";
import { getLoggedInOutlookUser } from "./office-document";

const validateToken = async () => {
  let MSAccessToken = await LocalMSTokens();
  try {
    const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
      headers: {
        Authorization: `Bearer ${MSAccessToken.access_token}`,
      },
    });
    const userData = response.data;

    let currentOutlookUser = await getLoggedInOutlookUser();

    if (
      userData.mail !== currentOutlookUser.emailAddress &&
      userData.userPrincipalName !== currentOutlookUser.emailAddress
    ) {
      throw new Error("Please log in with your Same Outlook account.");
    }

    return userData;
  } catch (error) {
    // console.error("Error validating token:", error);
    if (
      (error.response && (error.response.status === 401 || error.response.status === 403)) ||
      error.message.includes("Lifetime validation failed, the token is expired.")
    ) {
      return { error: true, message: "Token expired or invalid. Please refresh the token." };
    }
    return { error: true, message: error.message };
  }
};

const getOrganizationFromGraph = async () => {
  try {
    let MSAccessToken = await LocalMSTokens();

    if (!MSAccessToken) {
      return { error: true, message: "User is not logged in." };
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://graph.microsoft.com/v1.0/organization?$select=displayName,id,businessPhones,city,country,createdDateTime",
      headers: {
        Authorization: "Bearer " + MSAccessToken.access_token,
      },
    };

    const response = await axios.request(config);
    return response.data.value[0];
  } catch (error) {
    console.error("Error fetching organization data:", error);
    if (
      (error.response && (error.response.status === 401 || error.response.status === 403)) ||
      error.message.includes("Lifetime validation failed, the token is expired.")
    ) {
      return { error: true, message: "Token expired or invalid. Please refresh the token." };
    }
    return { error: true, message: error.message };
  }
};

const getOutlookContacts = async () => {
  let MSAccessToken = await LocalMSTokens();

  try {
    const response = await axios.get(
      "https://graph.microsoft.com/v1.0/me/contacts?$select=givenName,surname,emailAddresses,mobilePhone",
      {
        headers: {
          Authorization: `Bearer ${MSAccessToken.access_token}`,
        },
      }
    );
    const contacts = response.data.value.map((contact) => ({
      firstName: contact.givenName,
      lastName: contact.surname,
      email: contact.emailAddresses?.[0]?.address,
      phone: contact.mobilePhone,
    }));
    return contacts;
  } catch (error) {
    console.error("Error fetching contacts:", error);
    if (
      (error.response && (error.response.status === 401 || error.response.status === 403)) ||
      error.message.includes("Lifetime validation failed, the token is expired.")
    ) {
      return { error: true, message: "Token expired or invalid. Please refresh the token." };
    }
    return { error: true, message: error.message };
  }
};

export { validateToken, getOutlookContacts, getOrganizationFromGraph };
