import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getOrganizationFromGraph, validateToken } from "../utils/graph_api";
import Loader from "../components/ui/Loader";
import {
  getMGPToken,
  getMicrosoftTokens,
  GetOrganisationConfiguration,
  GetOrganisationUser,
  refreshAccessToken,
  SaveOrganisationUserAsync,
} from "../utils/api_template";
import {
  LocalMGPUser,
  LocalMSTokens,
  removeLocalMSTokens,
  setLocalAllMGPUserTokens,
  setLocalMGPUser,
  setLocalMSTokens,
} from "../utils/localstorage";
import { initializeUser, handleUserDetails } from "../utils/userUtils";

const MainContext = createContext(undefined);

export const useMainContext = () => {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error("useMainContext must be used within a MainContextProvider");
  }
  return context;
};

export const MainContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    initializeUser(setLoading, setUser, setError, callMGPRefreshToken, callRefreshToken, navigate);
  }, []);

  const handleInitializationError = (error) => {
    if (error === "Need to configure organization") {
      navigate("/complete_registration");
    } else {
      setError(error);
    }
  };

  const callMGPRefreshToken = async () => {
    try {
      let MGPUser = await LocalMGPUser();
      // console.log("MGP User:", MGPUser);

      let resultMGPToken = await getMGPToken(MGPUser.subscriptionId, MGPUser.tenant);
      if (resultMGPToken.error) {
        console.error("Error getting MGP tokens:", resultMGPToken.error);
        return;
      }

      await setLocalAllMGPUserTokens(resultMGPToken);
      let OrgUser = await GetOrganisationUser(MGPUser.microsoftUserId);
      setUser(OrgUser);

      return "Success";
    } catch (error) {
      console.error("Error refreshing MGP token:", error);
      setError(error.message);
    }
  };

  const callRefreshToken = async () => {
    try {
      let RefreshedSession = await refreshAccessToken();
      if (RefreshedSession.error) {
        console.error("Error refreshing token:", RefreshedSession.error);
        setError(RefreshedSession.error);
        return;
      }

      await setLocalMSTokens(RefreshedSession);
    } catch (error) {
      console.error("Error during token refresh:", error);
      setError(error.message);
    }
  };

  const afterLogin = useCallback(async (authorization_code) => {
    setLoading(true);

    try {
      let callTokenAPI = await getMicrosoftTokens(authorization_code);

      if (callTokenAPI.error) {
        console.error("Error getting tokens:", callTokenAPI.error);
        setLoading(false);
        setError(callTokenAPI.message);
        return "";
      }

      await setLocalMSTokens(callTokenAPI);

      let userDetail = await validateToken();

      if (userDetail.error) {
        console.error("Error validating token:", userDetail.error);
        setLoading(false);
        setError(userDetail.message);
        removeLocalMSTokens();
        return "";
      }

      let userOrganization = await getOrganizationFromGraph(callTokenAPI.access_token);

      if (userOrganization.error) {
        console.error("Error getting organization details:", userOrganization.error);
        setLoading(false);
        setError(userOrganization.message);
        return "";
      }
      // console.log("userOrganization", userOrganization);

      let checkUserOrgConfig = await GetOrganisationConfiguration(userOrganization.id);

      if (checkUserOrgConfig?.error) {
        setLoading(false);
        setError(checkUserOrgConfig.message);
        return "";
      }

      if (!checkUserOrgConfig) {
        console.error("No organization configuration found.");
        setLoading(false);
        // setError("Need to configure organization");
        return "Need to configure organization";
      }
      // console.log("checkUserOrgConfig", checkUserOrgConfig);

      let resultMGPToken = await getMGPToken(checkUserOrgConfig.subscriptionId, checkUserOrgConfig.tenant);

      if (resultMGPToken.error) {
        console.error("Error getting MGP tokens:", resultMGPToken.error);
        setLoading(false);
        setError(resultMGPToken.message);
        return "";
      }

      // console.log("resultMGPToken", resultMGPToken);

      await setLocalAllMGPUserTokens(resultMGPToken);

      let checkUserDetail = await GetOrganisationUser(userDetail.id);

      if (checkUserDetail.error) {
        console.error("Error getting user details from API:", checkUserDetail.error);
        setLoading(false);
        setError(checkUserDetail.message);
        return "";
      }

      if (checkUserDetail.length === 0) {
        let payload = {
          email: userDetail.mail,
          firstName: userDetail.givenName,
          lastName: userDetail.surname,
          subscriptionId: checkUserOrgConfig.subscriptionId,
          type: "user",
          organizationId: userOrganization.id,
          organizationName: userOrganization.displayName,
          microsoftUserId: userDetail.id,
          version: 0,
          tenant: checkUserOrgConfig.tenant,
          phoneNo: "",
        };
        console.log(payload);

        let saveUserResult = await SaveOrganisationUserAsync(payload);
        console.log(saveUserResult);

        checkUserDetail = await GetOrganisationUser(userDetail.id);
      }

      if (checkUserDetail.length > 0) {
        await setLocalMGPUser(checkUserDetail[0]);
        setUser(checkUserDetail[0]);
      }

      setLoading(false);
      return "Success";
    } catch (error) {
      console.error("Unexpected error in afterLogin:", error);
      setLoading(false);
      setError(error.message);
      return "Error";
    }
  }, []);

  const validateAPIKey = useCallback(async (apiKey, tenant) => {
    const result = await handleUserDetails(apiKey, tenant);
    if (result.status === "Error") {
      setError(result.message);
    }
    return result;
  }, []);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      loading,
      setLoading,
      afterLogin,
      validateAPIKey,
      error,
    }),
    [user, loading, afterLogin, validateAPIKey, error]
  );

  return (
    <MainContext.Provider value={contextValue}>
      {loading && <Loader />}
      {error && <div className="fixed bottom-0 w-full text-center bg-red-500 text-white py-2 z-50">{error}</div>}
      {children}
    </MainContext.Provider>
  );
};
