//export const MGPBaseURL = "https://integration-test.mygatepass.com";
export const MGPBaseURL = "https://integration.mygatepass.com";
export const refreshTokenPath = "api/refresh_token";
export const userPath = "api/user";
export const completeRegistrationPath = "api/complete_registration";
export const loginPath = "api/login";
export const mgpTokenPath = "api/tokens/GetTokenByKey";
export const createInvitationPath = "api/Invitations/CreateInvitationAsync";
export const getBuildingPath = "api/buildings";
export const getExistingInvitationPath = "api/Invitations/GetInvitationByIdAsync";
export const getAccessTokenPath = "api/OrganisationUser/GetAccessToken";
export const GetOrganisationConfigurationPath = "api/OrganisationUser/GetOrganisationConfiguration";
export const GetOrganisationUserPath = "api/OrganisationUser/GetOrganisationUser";
export const RefreshMSToken = "api/OrganisationUser/RefreshAccessToken";
export const SaveOrganisationUserAsyncPath = "api/OrganisationUser/SaveOrganisationUserAsync";
export const UpdateInvitationAsyncPath = "api/Invitations/UpdateInvitationAsync";