import axios from "axios";
import {
  completeRegistrationPath,
  createInvitationPath,
  getAccessTokenPath,
  getBuildingPath,
  getExistingInvitationPath,
  GetOrganisationConfigurationPath,
  GetOrganisationUserPath,
  MGPBaseURL,
  mgpTokenPath,
  RefreshMSToken,
  refreshTokenPath,
  SaveOrganisationUserAsyncPath,
  UpdateInvitationAsyncPath,
  userPath,
} from "./api_routes";
import { LocalAllMGPUserTokens, LocalMSTokens, LocalMGPUser } from "./localstorage";

const refreshAccessToken = async () => {
  let MSTokens = await LocalMSTokens();

  try {
    const response = await axios.post(`${MGPBaseURL}/${RefreshMSToken}`, {
      refreshToken: MSTokens.refresh_token,
    });

    return response.data;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return { error: true, message: error.response?.data?.messages[0] || error.message };
  }
};

const checkUserServer = async (email) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/${userPath}`, {
      params: { email },
    });
    return response.data.user;
  } catch (error) {
    console.error("Error checking user:", error);
    return { error: true, message: error.response?.data?.messages[0] || error.message };
  }
};

const completeUserRegistration = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/${completeRegistrationPath}`, data);
    return response.data;
  } catch (error) {
    console.error("Error during complete registration API call:", error);
    return { error: true, message: error.response?.data?.messages[0] || error.message };
  }
};

const getMGPToken = async (apikey, tenant) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${MGPBaseURL}/${mgpTokenPath}?key=` + apikey,
    headers: {
      "Accept-Language": "en-US",
      tenant: tenant,
    },
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error getting MGP token:", error);
    return { error: true, message: error.response?.data?.messages[0] || error.message };
  }
};

const createInvitation = async (data) => {
  let TokenMGP = await LocalAllMGPUserTokens();
  if (!TokenMGP) {
    return { error: true, message: "User is not logged in." };
  }
  let tenantStored = await LocalMGPUser();

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${MGPBaseURL}/${createInvitationPath}`,
    headers: {
      Authorization: "Bearer " + TokenMGP.data.token,
      tenant: tenantStored.tenant,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error creating invitation:", error);
    // return error;
    return { error: true, message: error };
  }
};

const updateInvitation = async (data) => {
  try {
    let TokenMGP = await LocalAllMGPUserTokens();
    if (!TokenMGP) {
      return { error: true, message: "User is not logged in." };
    }

    let tenantStored = await LocalMGPUser();

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${MGPBaseURL}/${UpdateInvitationAsyncPath}`,
      headers: {
        Authorization: "Bearer " + TokenMGP.data.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error updating invitation:", error);
    return { error: true, message: error };
  }
};

const getBuildings = async () => {
  let TokenMGP = await LocalAllMGPUserTokens();
  if (!TokenMGP) {
    return { error: true, message: "User is not logged in." };
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${MGPBaseURL}/${getBuildingPath}`,
    headers: {
      Authorization: "Bearer " + TokenMGP.data.token,
    },
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error getting buildings:", error);
    return { error: true, message: error.response?.data?.messages[0] || error.message };
  }
};

const getExistInvitationMGP = async (invitationID) => {
  let TokenMGP = await LocalAllMGPUserTokens();
  if (!TokenMGP) {
    return { error: true, message: "User is not logged in." };
  }
  let tenantStored = await LocalMGPUser();

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${MGPBaseURL}/${getExistingInvitationPath}?invitationId=` + invitationID,
    headers: {
      Authorization: "Bearer " + TokenMGP.data.token,
      tenant: tenantStored.tenant,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error getting existing invitation:", error);
    return { error: true, message: error.response?.data?.messages[0] || error.message };
  }
};

const getMicrosoftTokens = async (AuthorizationCode) => {
  let data = JSON.stringify({
    code: AuthorizationCode,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${MGPBaseURL}/${getAccessTokenPath}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error getting Microsoft tokens:", error);
    return { error: true, message: error.response?.data?.messages[0] || error.message };
  }
};

const GetOrganisationConfiguration = async (Org_ID) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${MGPBaseURL}/${GetOrganisationConfigurationPath}?orgId=${Org_ID}`,
    };

    const response = await axios.request(config);
    if (response.data.data.length > 0) {
      return response.data.data[0];
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching organization configuration:", error);
    return { error: true, message: error.response?.data?.messages[0] || error.message };
  }
};

const GetOrganisationUser = async (User_ID) => {
  let TokenMGP = await LocalAllMGPUserTokens();
  if (!TokenMGP) {
    return { error: true, message: "User is not logged in." };
  }

  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${MGPBaseURL}/${GetOrganisationUserPath}?userId=${User_ID}`,
      headers: {
        Authorization: `Bearer ${TokenMGP.data.token}`,
      },
    };

    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching organization user:", error);
    return {
      error: true,
      message: error.response?.data?.messages?.[0] || error.message,
    };
  }
};

const SaveOrganisationUserAsync = async (data) => {
  let TokenMGP = await LocalAllMGPUserTokens();
  if (!TokenMGP) {
    return { error: true, message: "User is not logged in." };
  }

  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${MGPBaseURL}/${SaveOrganisationUserAsyncPath}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenMGP.data.token}`,
      },
      data: JSON.stringify(data),
    };

    const response = await axios.request(config);
    return response.data.data;
  } catch (error) {
    console.error("Error saving organization user:", error);
    return {
      error: true,
      message:
        error.response?.data?.messages[0] ||
        (error.response && (error.response.status === 401 || error.response.status === 403)
          ? "Token is invalid or expired."
          : error.message),
    };
  }
};

export {
  refreshAccessToken,
  checkUserServer,
  completeUserRegistration,
  getBuildings,
  getMGPToken,
  createInvitation,
  updateInvitation,
  getExistInvitationMGP,
  getMicrosoftTokens,
  GetOrganisationConfiguration,
  GetOrganisationUser,
  SaveOrganisationUserAsync,
};
