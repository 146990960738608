import React, { useState } from "react";
import microsoftLogo from "../../../../assets/Microsoft.png";
import Loader from "../ui/Loader";
import { useMainContext } from "../../context/MainContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { setLoading, afterLogin } = useMainContext();
  const navigate = useNavigate();
  let LoginDialoge;

  const MSLogin = async () => {
    setLoading(true);
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    const REDIRECT_URI = process.env.REACT_APP_LOGIN_REDIRECT_URI;
    const SCOPES = [
      "Directory.Read.All",
      "openid",
      "profile",
      "offline_access",
      "User.Read",
      "Organization.Read.All",
      "Contacts.Read",
      "Contacts.ReadWrite",
    ];

    let url = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&response_mode=query&scope=${SCOPES.join(
      "%20"
    )}&state=12345&nonce=678910`;

    Office.context.ui.displayDialogAsync(url, { height: 60, width: 40 }, function (asyncResult) {
      LoginDialoge = asyncResult.value;
      LoginDialoge.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      LoginDialoge.addEventHandler(Office.EventType.DialogEventReceived, dialogAction);
    });
  };

  const dialogAction = (arg) => {
    if (arg.error === 12006) {
      // console.log("Dialog was closed by the user.");
    } else {
      // console.log("Dialog action received:", arg);
    }
    setLoading(false);
  };

  const processMessage = async (arg) => {
    const receivedMessage = arg.message;
    const code = receivedMessage.trim();

    if (code) {
      try {
        let LoginResult = await afterLogin(code);
        LoginDialoge.close();
        if (LoginResult === "Success") {
          navigate("/home");
        } else if (LoginResult === "Need to configure organization") {
          navigate("/complete_registration");
        }
      } catch (error) {
        console.error("Error during API call:", error);
        LoginDialoge.close();
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      LoginDialoge.close();
      console.error("Authorization code not found in the message");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 relative">
      <h1 className="text-2xl font-bold mb-2">Welcome to MyGatePass</h1>
      <small className="text-gray-700 mb-8">A secure way to manage your external visitors.</small>

      <button
        className="flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        onClick={MSLogin}
      >
        <img src={microsoftLogo} alt="Microsoft Logo" className="w-5 h-5 mr-2" />
        Login with Microsoft
      </button>
    </div>
  );
}
