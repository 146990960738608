import React, { useState, useEffect } from "react";
import { getBuildings, getExistInvitationMGP } from "../../utils/api_template";
import { getAttendees, getCustomProperty, getMeetingData, setCustomProperty } from "../../utils/office-document";
import { useMainContext } from "../../context/MainContext";
import {
  LocalAllMGPUserTokens,
  LocalMSTokens,
  LocalMGPUser,
  removeLocalAllMGPUserTokens,
  removeLocalMGPUser,
  removeLocalMSTokens,
} from "../../utils/localstorage";
import CustomDropdown from "../ui/CustomDropdown";
import CustomCheckbox from "../ui/CustomCheckbox";
import { FaPowerOff } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { setLoading, setUser } = useMainContext();
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [externalGuests, setExternalGuests] = useState([]);
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [existingInvitation, setExistingInvitation] = useState(null);
  const [meetingExist, setMeetingExist] = useState(null);
  const [isSmsNotificationEnabled, setSmsNotificationEnabled] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(""); // State for storing error message

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        await LocalAllMGPUserTokens();
        await LocalMSTokens();

        let LoggedInUser = await LocalMGPUser();

        // console.log("Logged In User", LoggedInUser);

        setPhoneNumber(LoggedInUser.phoneNo);
        localStorage.setItem("phoneNumber", LoggedInUser.phoneNo);

        addRecipientsChangedHandler();

        const buildingData = await getBuildings();
        setBuildings(buildingData.data || []);

        const attendees = await getAttendees();
        setExternalGuests(attendees);

        const existedMeetingId = await getCustomProperty();

        if (existedMeetingId) {
          setExistingInvitation(`Invitation already exists with ID: ${existedMeetingId}`);

          const meetingResult = await getExistInvitationMGP(existedMeetingId);
          setMeetingExist(meetingResult);

          if (meetingResult && buildingData.data && buildingData.data.length > 0) {
            const selectedBuilding = buildingData.data.find((b) => b.id === meetingResult.buildingId);
            setSelectedBuilding(selectedBuilding);

            const guestEmailsInMeeting = new Set(meetingResult.guestInvitations.map((guest) => guest.email));
            const selectedGuests = attendees.filter((guest) => guestEmailsInMeeting.has(guest.email));

            setSelectedGuests(selectedGuests);
          }
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading]);

  const addRecipientsChangedHandler = () => {
    Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecipientsChanged, handleRecipientsChanged);
  };

  const handleRecipientsChanged = async () => {
    try {
      const recipients = await getAttendees();
      setExternalGuests(recipients);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBuildingChange = (option) => {
    setSelectedBuilding(option);
  };

  useEffect(() => {
    localStorage.setItem("selectedBuilding", JSON.stringify(selectedBuilding));
  }, [selectedBuilding]);

  const handleGuestChange = (guest) => {
    setSelectedGuests((prevSelected) => {
      const isSelected = prevSelected.some((selectedGuest) => selectedGuest.email === guest.email);

      if (isSelected) {
        return prevSelected.filter((selectedGuest) => selectedGuest.email !== guest.email);
      } else {
        return [...prevSelected, guest];
      }
    });
  };

  useEffect(() => {
    localStorage.setItem("selectedGuests", JSON.stringify(selectedGuests));
  }, [selectedGuests]);

  const handleLogoffClick = () => {
    const logoutURL = `https://login.microsoftonline.com/organizations/oauth2/v2.0/logout?post_logout_redirect_uri=${process.env.REACT_APP_LOGOUT_REDIRECT_URI}&client_id=${process.env.REACT_APP_CLIENT_ID}`;

    Office.context.ui.displayDialogAsync(logoutURL, { height: 60, width: 40 }, function (asyncResult) {
      const logoutDialog = asyncResult.value;
      logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
        removeLocalAllMGPUserTokens();
        removeLocalMGPUser();
        removeLocalMSTokens();
        localStorage.removeItem("selectedBuilding");
        localStorage.removeItem("selectedGuests");

        logoutDialog.close();
        setUser(null);
        navigate("/");
      });
      logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, (arg) => {
        if (arg.error === 12006) {
          removeLocalAllMGPUserTokens();
          removeLocalMGPUser();
          removeLocalMSTokens();
          localStorage.removeItem("selectedBuilding");
          localStorage.removeItem("selectedGuests");

          logoutDialog.close();
          setUser(null);
          navigate("/");
        } else {
          console.log("Dialog action received:", arg);
        }
      });
    });
  };

  const handleSmsNotificationChange = (event) => {
    const isChecked = event.target.checked;
    setSmsNotificationEnabled(isChecked);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);

    // Validate phone number
    if (event.target.value.length < 10) {
      setPhoneNumberError("Phone number must be at least 10 digits.");
    } else {
      setPhoneNumberError("");
      localStorage.setItem("phoneNumber", event.target.value);
    }
  };

  return (
    <div className="relative flex justify-center min-h-screen">
      <div className="w-80 p-4">
        <h1 className="text-lg font-semibold mb-4 text-gray-800">Create external access for your visitors.</h1>
        <p className="text-gray-600 mb-6 text-sm">
          Select the external visitors in the list and send them personal access QR codes.
        </p>

        <button onClick={handleLogoffClick} className="absolute top-4 right-8" aria-label="Logoff">
          <FaPowerOff size={18} />
        </button>

        <div className="grid gap-2 max-w-full">
          <label className="block text-xs font-medium text-gray-700 mb-1">Building</label>
          <CustomDropdown
            options={buildings}
            selectedOption={selectedBuilding}
            onChange={handleBuildingChange}
            placeholder="Select Building"
          />
        </div>

        <div className="my-4">
          <label htmlFor="external-guests" className="block text-xs font-medium text-gray-700 mb-1">
            External Guests
          </label>
          <div id="external-guests" className="mt-1">
            {externalGuests.map((guest, index) => (
              <CustomCheckbox
                key={guest.email}
                id={`guest-checkbox-${guest.email}`}
                label={guest.firstName + " " + guest?.lastName}
                checked={selectedGuests.some((selectedGuest) => selectedGuest.email === guest.email)}
                onChange={() => handleGuestChange(guest)}
                disabled={!selectedBuilding}
              />
            ))}
          </div>
        </div>

        {/* SMS Notification Section */}
        <div className="my-4">
          <label htmlFor="sms-notification" className="block text-xs font-medium text-gray-700 mb-1">
            Sms Notification
          </label>
          <div id="sms-notification" className="flex items-center mt-1">
            <CustomCheckbox
              id="sms-notification-checkbox"
              label="Send SMS notification when my guest arrives"
              checked={isSmsNotificationEnabled}
              onChange={handleSmsNotificationChange}
            />
          </div>
        </div>

        <div className="mt-4">
          <label htmlFor="phone-number" className="block text-xs font-medium text-gray-700 mb-1">
            Phone Number
          </label>
          <input
            type="text"
            id="phone-number"
            className="border border-gray-300 rounded p-2 w-full"
            placeholder="9715xxxxxxxx"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            readOnly={!isSmsNotificationEnabled}
          />
          {phoneNumberError && <p className="text-red-500 text-xs mt-1">{phoneNumberError}</p>}
        </div>
      </div>
    </div>
  );
}
