import { decryptData, encryptData } from "./utils";

export const LocalAllMGPUserTokens = async () => {
  const encryptedData = localStorage.getItem("AllMGPTokens");
  if (encryptedData) {
    return await decryptData(encryptedData);
  }
  return null;
};

export const LocalMGPUser = async () => {
  const encryptedData = localStorage.getItem("MGPUser");
  if (encryptedData) {
    return await decryptData(encryptedData);
  }
  return null;
};

export const LocalMSTokens = async () => {
  const encryptedData = localStorage.getItem("MGP-MSTokens");
  if (encryptedData) {
    return await decryptData(encryptedData);
  }
  return null;
};

export const setLocalAllMGPUserTokens = async (data) => {
  const encryptedData = await encryptData(data);
  localStorage.setItem("AllMGPTokens", encryptedData);
};

export const setLocalMGPUser = async (data) => {
  const encryptedData = await encryptData(data);
  localStorage.setItem("MGPUser", encryptedData);
};

export const setLocalMSTokens = async (data) => {
  const encryptedData = await encryptData(data);
  localStorage.setItem("MGP-MSTokens", encryptedData);
};

export const removeLocalAllMGPUserTokens = () => {
  localStorage.removeItem("AllMGPTokens");
};

export const removeLocalMGPUser = () => {
  localStorage.removeItem("MGPUser");
};

export const removeLocalMSTokens = () => {
  localStorage.removeItem("MGP-MSTokens");
};
