import { getOutlookContacts } from "./graph_api";

const getAttendees = async () => {
  try {
    // Ensure the Office context is ready
    await Office.onReady();

    // Check if the item is an appointment or meeting
    if (Office.context.mailbox.item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      const item = Office.context.mailbox.item;

      // Get required attendees asynchronously
      const requiredAttendees = await new Promise((resolve, reject) => {
        item.requiredAttendees.getAsync((result) => {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            resolve(result.value);
          } else {
            reject(result.error);
          }
        });
      });

      let OutlookContactResult = await getOutlookContacts();
      // console.log(OutlookContactResult);

      const attendees = requiredAttendees.map((attendee) => {
        let contact = OutlookContactResult.find((contact) => contact.email === attendee.emailAddress);
        if (contact) {
          return {
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            phone: contact.phone,
          };
        } else {
          return {
            email: attendee.emailAddress,
            firstName: attendee.displayName.split(" ")[0],
            lastName: attendee.displayName.split(" ")[1] || "",
            phone: "",
          };
        }
      });

      // Extract necessary details (e.g., email and name)
      // const attendees = requiredAttendees.map((attendee) => ({
      //   email: attendee.emailAddress,
      //   name: attendee.displayName,
      // }));

      return attendees;
    } else {
      console.log("This item is not an appointment or meeting.");
      return [];
    }
  } catch (error) {
    console.error("Error getting attendees:", error);
    return [];
  }
};

const getMeetingData = async () => {
  try {
    const title = await new Promise((resolve, reject) => {
      Office.context.mailbox.item.subject.getAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed || asyncResult.value === undefined) {
          reject(new Error("Failed to get subject"));
        } else {
          resolve(asyncResult.value);
        }
      });
    });

    const description = await new Promise((resolve, reject) => {
      Office.context.mailbox.item.body.getAsync("text", (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed || asyncResult.value === undefined) {
          reject(new Error("Failed to get body text"));
        } else {
          resolve(asyncResult.value);
        }
      });
    });

    const location = await new Promise((resolve, reject) => {
      Office.context.mailbox.item.location.getAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed || asyncResult.value === undefined) {
          reject(new Error("Failed to get location"));
        } else {
          resolve(asyncResult.value);
        }
      });
    });

    const start = await new Promise((resolve, reject) => {
      Office.context.mailbox.item.start.getAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed || asyncResult.value === undefined) {
          reject(new Error("Failed to get start time"));
        } else {
          const startDate = new Date(asyncResult.value);
          resolve(formatDate(startDate));
        }
      });
    });

    const end = await new Promise((resolve, reject) => {
      Office.context.mailbox.item.end.getAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed || asyncResult.value === undefined) {
          reject(new Error("Failed to get end time"));
        } else {
          const endDate = new Date(asyncResult.value);
          resolve(formatDate(endDate));
        }
      });
    });

    const organizer = await new Promise((resolve, reject) => {
      Office.context.mailbox.item.organizer.getAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed || asyncResult.value === undefined) {
          reject(new Error("Failed to get organizer"));
        } else {
          resolve(asyncResult.value);
        }
      });
    });

    const isRepetitive = await new Promise((resolve, reject) => {
      Office.context.mailbox.item.recurrence.getAsync((asyncResult) => {
        // console.log(asyncResult.value);

        if (
          asyncResult.status === Office.AsyncResultStatus.Failed ||
          asyncResult.value === undefined ||
          asyncResult.value === null
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });

    const MeetingDetail = {
      title: title,
      description: description,
      location: location,
      start_date: start,
      end_date: end,
      organizer: organizer,
      isRepetitive: isRepetitive,
    };

    //   console.log(MeetingDetail);

    return MeetingDetail;
  } catch (error) {
    console.error("Failed to get meeting data", error);
  }
};
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

// const setCustomProperty = async (meetingID) => {
//   return new Promise((resolve, reject) => {
//     Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
//       if (result.status === Office.AsyncResultStatus.Failed) {
//         reject(new Error(`Failed to load custom properties: ${result.error.message}`));
//         return;
//       }

//       const customProperties = result.value;
//       customProperties.set("meetingMGPid", meetingID);

//       customProperties.saveAsync((saveResult) => {
//         if (saveResult.status === Office.AsyncResultStatus.Failed) {
//           reject(new Error(`Failed to save custom properties: ${saveResult.error.message}`));
//         } else {
//           resolve();
//         }
//       });
//     });
//   });
// };

const setCustomProperty = async (meetingID) => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        reject({
          status: false,
          meetingID: null,
          message: `Failed to load custom properties: ${result.error.message}`,
        });
        return;
      }

      const customProperties = result.value;
      customProperties.set("meetingMGPid", meetingID);

      customProperties.saveAsync((saveResult) => {
        if (saveResult.status === Office.AsyncResultStatus.Failed) {
          reject({
            status: false,
            meetingID: null,
            message: `Failed to save custom properties: ${saveResult.error.message}`,
          });
        } else {
          resolve({ status: true, meetingID: meetingID });
        }
      });
    });
  });
};

const getCustomProperty = async () => {
  try {
    const result = await new Promise((resolve, reject) => {
      Office.context.mailbox.item.loadCustomPropertiesAsync((result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          reject(new Error(`Failed to load custom properties: ${result.error.message}`));
          return;
        }

        const customProperties = result.value;
        const customValue = customProperties.get("meetingMGPid");

        resolve(customValue);
      });
    });

    return result;
  } catch (error) {
    console.error("Failed to get custom property:", error);
    return null;
  }
};

const getLoggedInOutlookUser = async () => {
  const organizer = await new Promise((resolve, reject) => {
    Office.context.mailbox.item.organizer.getAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed || asyncResult.value === undefined) {
        reject(new Error("Failed to get organizer"));
      } else {
        resolve(asyncResult.value);
      }
    });
  });

  return organizer;
};

export { getAttendees, getMeetingData, setCustomProperty, getCustomProperty, getLoggedInOutlookUser };
