import React, { useEffect, useState } from "react";
import Loader from "../ui/Loader";
import { completeUserRegistration, GetOrganisationUser, SaveOrganisationUserAsync } from "../../utils/api_template";
import { useMainContext } from "../../context/MainContext";
import { useNavigate } from "react-router-dom";
import { getOrganizationFromGraph, validateToken } from "../../utils/graph_api";
import { setLocalMGPUser } from "../../utils/localstorage";

export default function CompleteRegistration() {
  const { updateUserDetails, user, loading, setLoading, validateAPIKey, setUser } = useMainContext();
  const [apiKey, setApiKey] = useState("");
  const [tenant, setTenant] = useState("");
  const [userName, setUserName] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [userOrgDetails, setUserOrgDetails] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const GetUserWithOrg = async () => {
      try {
        setLoading(true);
        const UserDetail = await validateToken();

        // console.log(UserDetail);

        const UserOrgDetail = await getOrganizationFromGraph();
        setUserDetails(UserDetail);
        setUserOrgDetails(UserOrgDetail);
        setUserName(UserDetail?.displayName || "");
        // console.log(UserOrgDetail);
      } catch (error) {
        console.error("Error fetching user or organization details:", error);
      } finally {
        setLoading(false);
      }
    };

    GetUserWithOrg();
  }, [setLoading]);

  const onSubmitRegistration = async () => {
    setLoading(true);

    const payload = {
      email: userDetails?.mail,
      firstName: userDetails?.givenName,
      lastName: userDetails?.surname,
      subscriptionId: apiKey,
      type: "user",
      organizationId: userOrgDetails?.id,
      organizationName: userOrgDetails?.displayName,
      microsoftUserId: userDetails?.id,
      version: 0,
      tenant: tenant,
      phoneNo: "",
    };

    // console.log(payload);

    try {
      const resultAPIKeyValidation = await validateAPIKey(apiKey, tenant);

      if (resultAPIKeyValidation?.status === "Error") {
        console.error(resultAPIKeyValidation?.message);
        setLoading(false);
        return;
      }

      const resultSaveOrg = await SaveOrganisationUserAsync(payload);

      if (resultSaveOrg.error) {
        console.error(resultSaveOrg.error);
        setLoading(false);
        return;
      }

      let getUserMGP = await GetOrganisationUser(userDetails?.id);

      if (getUserMGP.error) {
        console.error(getUserMGP.error);
        setLoading(false);
        return;
      }
      setUser(getUserMGP[0]);
      setLocalMGPUser(getUserMGP[0]);

      if (resultSaveOrg) {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error during registration:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      {loading && <Loader />}
      <input
        readOnly
        type="text"
        placeholder="Full Name"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        className="mb-4 px-4 py-2 border rounded-lg"
      />
      <input
        type="text"
        placeholder="API Key"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        className="mb-4 px-4 py-2 border rounded-lg"
      />
      <input
        type="text"
        placeholder="Tenant"
        value={tenant}
        onChange={(e) => setTenant(e.target.value)}
        className="mb-4 px-4 py-2 border rounded-lg"
      />
      <button
        className="px-6 py-3 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        onClick={onSubmitRegistration}
      >
        Complete Registration
      </button>
    </div>
  );
}
