import React from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { MainContextProvider, useMainContext } from "../context/MainContext";
import ProtectedRoute from "./ProtectedRoute";
import Home from "./pages/Home";
import Login from "./pages/Login";
import CompleteRegistration from "./pages/CompleteRegistration";

const App = () => {
  return (
    <MainContextProvider>
      <AppRoutes />
    </MainContextProvider>
  );
};

const AppRoutes = () => {
  const { user } = useMainContext();

  return (
    <Routes>
      <Route path="/" element={user ? <Navigate to="/home" replace /> : <Login />} />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/complete_registration"
        element={
          // <ProtectedRoute>
            <CompleteRegistration />
          // </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
