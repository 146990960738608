import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { HashRouter as Router } from "react-router-dom";

/* global document, Office, module, require */

const rootElement = document.getElementById("container");
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
  root.render(
    <FluentProvider theme={webLightTheme}>
      <Router>
        <App />
      </Router>
    </FluentProvider>
  );
});

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root.render(
      <FluentProvider theme={webLightTheme}>
        <MainContextProvider>
          <NextApp />
        </MainContextProvider>
      </FluentProvider>
    );
  });
}
