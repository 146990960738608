import React from "react";

const CustomCheckbox = ({ id, label, checked, onChange, disabled }) => {
  return (
    <div className={`flex items-center cursor-pointer ${disabled ? 'cursor-not-allowed' : ''}`}>
      <input
        id={id} // Ensure unique ID for each checkbox
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled} // Apply the disabled prop
        className="form-checkbox h-4 w-4 text-purple-600 transition duration-150 ease-in-out"
      />
      <label htmlFor={id} className={`ml-2 text-gray-700 ${disabled ? 'text-gray-400' : ''}`}>
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
