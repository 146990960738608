import {
  LocalMGPUser,
  LocalMSTokens,
  LocalAllMGPUserTokens,
  setLocalAllMGPUserTokens,
  setLocalMGPUser,
  removeLocalAllMGPUserTokens,
  removeLocalMGPUser,
  removeLocalMSTokens,
} from "./localstorage";
import { getOrganizationFromGraph, validateToken } from "./graph_api";
import { GetOrganisationConfiguration, GetOrganisationUser, getMGPToken } from "./api_template";

const validateAndRefreshToken = async (tokens, callRefreshToken, setError) => {
  const validateTokenResult = await validateToken(tokens.access_token);
  if (validateTokenResult?.message === "Token expired or invalid. Please refresh the token.") {
    // console.log("Token is invalid or expired.");
    await callRefreshToken();
    return { isValid: false, userDetail: null };
  } else if (validateTokenResult?.message) {
    console.error("Error validating token:", validateTokenResult.error);
    setError(validateTokenResult.message);
    return { isValid: false, userDetail: null };
  }

  return { isValid: true, userDetail: validateTokenResult };
};

export const initializeUser = async (
  setLoading,
  setUser,
  setError,
  callMGPRefreshToken,
  callRefreshToken,
  navigate
) => {
  setLoading(true);
  setError(null); // Reset the error state before initialization

  try {
    let storedMSTokens = await LocalMSTokens();
    let storedMGPTokens = await LocalAllMGPUserTokens();
    const storedUser = await LocalMGPUser();

    // Validate and refresh Microsoft tokens if necessary
    let userDetail = null;
    if (storedMSTokens) {
      const { isValid, userDetail: validatedUserDetail } = await validateAndRefreshToken(
        storedMSTokens,
        callRefreshToken,
        setError
      );
      if (!isValid) {
        // Refresh the stored tokens after refreshing
        storedMSTokens = await LocalMSTokens();
        const validationResult = await validateAndRefreshToken(storedMSTokens, callRefreshToken, setError);
        userDetail = validationResult.userDetail;
        if (!validationResult.isValid) {
          console.error("Failed to refresh token, logging out.");
          // logoutUser(setUser, navigate, setError);
          return;
        }
      } else {
        userDetail = validatedUserDetail;
      }
    }

    // Ensure both tokens are available
    if (!storedMSTokens || !storedMGPTokens) {
      setLoading(false);
      // logoutUser(setUser, navigate, setError);
      return;
    }

    // Check user organization configuration if Microsoft tokens are valid
    if (storedMSTokens && !storedUser) {
      let userOrganization = await getOrganizationFromGraph();

      let checkUserOrgConfig = await GetOrganisationConfiguration(userOrganization.id);

      if (checkUserOrgConfig?.error) {
        setLoading(false);
        setError(checkUserOrgConfig.error); // Set the error state
        // logoutUser(setUser, navigate, setError);
        return;
      }

      if (!checkUserOrgConfig) {
        console.error("No organization configuration found.");
        setLoading(false);
        setError("Need to configure organization");
        // logoutUser(setUser, navigate, setError);
        return "Need to configure organization";
      }
      // console.log("checkUserOrgConfig", checkUserOrgConfig);
    }

    // Validate user and MGP tokens using the user data from validateToken
    if (storedMGPTokens && userDetail) {
      let checkUserDetail = await GetOrganisationUser(userDetail.id);
      // console.log("User Details:", checkUserDetail);

      if (checkUserDetail.error) {
        if (checkUserDetail.message === "Request failed with status code 401") {
          console.log("Token is invalid or expired. Refreshing token.");
          let refreshedResult = await callMGPRefreshToken();
          console.log("refreshedResult", refreshedResult);
        } else {
          console.error("Error getting user details from API:", checkUserDetail.error);
          setUser(null);
          setLoading(false);
          setError(checkUserDetail.error); // Set the error state
          // logoutUser(setUser, navigate, setError);
          return;
        }
      } else {
        let MGPTokens = await getMGPToken(checkUserDetail[0]?.subscriptionId, checkUserDetail[0]?.tenant);

        if (MGPTokens.error) {
          console.error("Error getting MGP tokens:", MGPTokens.error);
          setUser(null);
          setLoading(false);
          setError(MGPTokens.message); // Set the error state
          logoutUser(setUser, navigate, setError);
          return;
        }

        await setLocalAllMGPUserTokens(MGPTokens);
        await setLocalMGPUser(checkUserDetail[0]);
        setUser(checkUserDetail[0]);
      }
    }
  } catch (error) {
    console.error("Error during initialization:", error);
    setError(error.message); // Set the error state
    // logoutUser(setUser, navigate, setError);
  } finally {
    setLoading(false);
  }
};

const logoutUser = (setUser, navigate, setError) => {
  removeLocalAllMGPUserTokens();
  removeLocalMGPUser();
  removeLocalMSTokens();
  setUser(null);
  setError("User is logged out due to token error.");
  navigate("/");
};

export const handleUserDetails = async (apiKey, tenant) => {
  try {
    let resultMGPToken = await getMGPToken(apiKey, tenant);

    if (resultMGPToken.error) {
      console.error("Error getting MGP tokens:", resultMGPToken.error);
      return { status: "Error", message: resultMGPToken.message };
    }

    await setLocalAllMGPUserTokens(resultMGPToken);
    return { status: "Success" };
  } catch (error) {
    return { status: "Error", message: error.response?.data?.messages[0] || error.message || "Unknown error occurred" };
  }
};
